<template>
  <div>
    <pv-toast position="bottom-right" />
    <pv-toast
      ref="toastRef"
      position="bottom-right"
      :group="groupName"
      :base-z-index="toastZIndex"
    >
      <template #message="slotProps">
        <div class="flex flex-col items-center w-full gap-4 p-2">
          <div class="text-center">
            <i
              v-if="slotProps.message.summary"
              class="pi pi-exclamation-triangle"
              style="font-size: 2.5rem"
            ></i>
            <h4 v-if="slotProps.message.summary">
              {{ slotProps.message.summary }}
            </h4>
            <p v-if="slotProps.message.detail">
              {{ slotProps.message.detail }}
            </p>
          </div>
          <div class="flex justify-center gap-1 w-full" style="gap: 0.25rem">
            <div
              v-for="(button, index) in slotProps.message.buttons"
              :key="`${slotProps.message.id}${index}`"
              class="w-full"
            >
              <pv-button
                class="w-full"
                :class="{
                  'p-button-success': index === 0,
                  'p-button-secondary': index !== 0,
                }"
                :label="button.text"
                @click="handleAction(button.action, slotProps)"
              />
            </div>
          </div>
        </div>
      </template>
    </pv-toast>
  </div>
</template>

<script>
import PvToast from "primevue/toast";
import PvButton from "primevue/button";
import Event from "@/plugins/bus";
import { PRIMEVUE_TOAST_Z_INDEX } from "@/constants/z-index";

export default {
  name: "CustomToast",

  components: {
    PvToast,
    PvButton,
  },

  computed: {
    groupName() {
      return "custom";
    },
    toastZIndex() {
      return PRIMEVUE_TOAST_Z_INDEX;
    },
  },

  created() {
    Event.$on("open-toast", (event) => {
      this.$toast.add(event);
    });
  },

  beforeUnmount() {
    Event.$off("open-toast");
  },

  methods: {
    async handleAction(action, message) {
      try {
        if (action) {
          await action();
        }
      } finally {
        console.log("this.$refs.toastRef", message);
        this.$refs.toastRef.remove(message);
      }
    },
  },
};
</script>
