import * as types from "../mutation-types";
import { httpApiClient } from "@/services";

const state = {
  fields: [],
  fieldTypes: [],
  fieldGroups: [],
};

const getters = {
  fields: (state) => state.fields.sort((a, b) => a.order_nr - b.order_nr),
  fieldsByModel: (state) => (modelName) =>
    state.fields
      .filter((item) => item.model_type === modelName)
      .sort((a, b) => a.order_nr - b.order_nr),
  fieldTypes: (state) => state.fieldTypes,
  fieldGroups: (state) =>
    state.fieldGroups.sort((a, b) => a.order_nr - b.order_nr),
  getIdFromType: (state) => (typeName) => {
    return state.fieldTypes.find((type) => type.slug === typeName)?.id;
  },
  getFieldFromSlug: (state) => (slug) => {
    return state.fields.find((field) => field.slug === slug);
  },
};

const mutations = {
  [types.SET_FIELDS](state, { fields }) {
    state.fields = fields;
  },
  [types.SET_FIELD_TYPES](state, { fieldTypes }) {
    state.fieldTypes = fieldTypes;
  },
  [types.SET_FIELD_GROUPS](state, { fieldGroups }) {
    state.fieldGroups = fieldGroups;
  },
};

const actions = {
  setFields({ commit }, { fields }) {
    commit(types.SET_FIELDS, { fields });
  },
  setFieldTypes({ commit }, { fieldTypes }) {
    commit(types.SET_FIELD_TYPES, { fieldTypes });
  },
  setFieldGroups({ commit }, { fieldGroups }) {
    commit(types.SET_FIELD_GROUPS, { fieldGroups });
  },
  async fetchFields({ commit }) {
    try {
      const {
        data: { data },
      } = await httpApiClient.get("field");

      commit(types.SET_FIELDS, { fields: data });
    } catch (e) {
      console.error("Error on fetching fields: ", e);
    }
  },
  async fetchFieldTypes({ commit }) {
    try {
      const {
        data: { data },
      } = await httpApiClient.get("field_type");

      commit(types.SET_FIELD_TYPES, { fieldTypes: data });
    } catch (e) {
      console.error("Error on fetching field types: ", e);
    }
  },
  async fetchFieldGroups({ commit }) {
    try {
      const {
        data: { data },
      } = await httpApiClient.get("field_group");

      commit(types.SET_FIELD_GROUPS, { fieldGroups: data });
    } catch (e) {
      console.error("Error on fetching field groups: ", e);
    }
  },
  async fetchAll({ dispatch }) {
    await Promise.all([
      dispatch("fetchFields"),
      dispatch("fetchFieldTypes"),
      dispatch("fetchFieldGroups"),
    ]);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
