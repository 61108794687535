import * as types from "../mutation-types";
import { httpApiClient } from "@/services";

const state = {
  settings: null,
};

const getters = {
  settings: (state) => state.settings,
};

const mutations = {
  [types.UPDATE_SETTINGS](state, { settings }) {
    state.settings = settings;
  },
};

const actions = {
  updateSettings({ commit }, payload) {
    commit(types.UPDATE_SETTINGS, payload);
  },
  async fetchSettings({ commit }) {
    try {
      const { data } = await httpApiClient.get("setting");
      let settings = {};
      data.data.forEach((e) => {
        settings[e.slug] = e.value;
      });
      commit(types.UPDATE_SETTINGS, { settings: settings });
    } catch (e) {
      console.error(e);
    }
  },
};

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};
