import Cookies from "js-cookie";
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from "vue-router";

export default async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext
) => {
  const isOk = !!Cookies.get("token");
  if (!isOk) {
    return next({ name: "login" });
  } else {
    return next();
  }
};
