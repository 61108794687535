/* eslint-disable @typescript-eslint/no-unused-vars */
import * as types from "../mutation-types";
import Fuse from "fuse.js";
import Events from "@/plugins/bus";
import dayjs from "dayjs";
import { httpApiClient } from "@/services";

const state = {
  loading: false,
  dragging: false,
  search: null,
  user: null,
  pipeline: null,
  pipelines: [],
  stages: [],
  deals: [] as any[],
  has_persons: [],
  contacts: [],
  companies: [],
  currencies: [],
  deal_products: [],
  tasks: [],
  show_drop: [],
  mode: "block",
};

const mutations = {
  [types.PIPELINE_SET_USER](state, user) {
    state.user = user;
  },
  [types.PIPELINE_SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [types.PIPELINE_SET_DRAGGING](state, dragging) {
    state.dragging = dragging;
  },
  [types.PIPELINE_SET_SEARCH](state, search) {
    state.search = search;
  },
  [types.PIPELINE_SET_PIPELINE](state, pipeline) {
    state.pipeline = pipeline;
  },
  [types.PIPELINE_SET_PIPELINES](state, pipelines) {
    state.pipelines = pipelines;
  },
  [types.PIPELINE_SET_STAGES](state, stages) {
    state.stages = stages;
  },
  [types.PIPELINE_SET_DEALS](state, deals) {
    state.deals = deals;
  },
  [types.PIPELINE_SET_HAS_PERSONS](state, has_persons) {
    state.has_persons = has_persons;
  },
  [types.PIPELINE_SET_PERSONS](state, contacts) {
    state.contacts = contacts;
  },
  [types.PIPELINE_SET_TASKS](state, tasks) {
    state.tasks = tasks;
  },
  [types.PIPELINE_SET_TASK](state, task) {
    state.tasks = [...state.tasks.filter((e) => e.id !== task.id), task];
  },
  [types.PIPELINE_SET_SHOW_DROP](state, showDrop) {
    state.show_drop = showDrop;
  },
  [types.PIPELINE_ADD_DEAL](state, deal) {
    state.deals.push(deal);
  },
  [types.PIPELINE_UPDATE_DEAL](state, deal) {
    state.deals = [...state.deals.filter((e) => e.id !== deal.id), deal];
  },
  [types.PIPELINE_DEAL_SET_STAGE](state, { deal_id, stage_id }) {
    const index = state.deals.findIndex((e) => e.id == deal_id);
    if (index != -1) {
      // const item = {
      //   ...state.deals[index],
      //   stage_id,
      // };
      state.deals[index].stage_id = stage_id;
      // state.deals.splice(index, 1, item);
    }
  },
  [types.PIPELINE_SET_MODE](state, mode) {
    state.mode = mode;
  },
};

const getDealById = ({ deal_id, state }) => {
  const index = state.deals.findIndex((e) => e.id == deal_id);
  const old_deal = JSON.parse(JSON.stringify(state.deals[index]));
  return { index, old_deal };
};

const actions = {
  setUser({ commit }, user) {
    commit(types.PIPELINE_SET_USER, user);
  },
  setLoading({ commit }, loading) {
    commit(types.PIPELINE_SET_LOADING, loading);
  },
  setSearch({ commit }, search) {
    commit(types.PIPELINE_SET_SEARCH, search ? search.trim() : search);
  },
  async fetchPipelines({ commit, state }) {
    try {
      const { data } = await httpApiClient.get("pipeline");

      commit(
        types.PIPELINE_SET_PIPELINE,
        data.data.find((e) => e.id == state.user.pipeline_id)
      );
      commit(types.PIPELINE_SET_PIPELINES, data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchStages({ commit, state }) {
    try {
      const { data } = await httpApiClient.get("stage", {
        params: { pipeline_id: state.pipeline.id },
      });
      commit(types.PIPELINE_SET_STAGES, data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchDeals({ commit }) {
    try {
      const { data } = await httpApiClient.get("deal-pipeline", {
        params: {
          with: ["currency", "contacts", "company", "consultor"],
          per_page: 100,
        },
      });
      commit(types.PIPELINE_SET_DEALS, data.data);
    } catch (error) {
      console.error(error);
    }
  },
  async setTaskStatusById({ commit, state }, { task_id, status }) {
    try {
      const { data } = await httpApiClient.patch(`task/${task_id}`, {
        id: task_id,
        action: status ? "done" : "undone",
      });
      const deal = state.deals.find((e) => e.id == data.deal_id);
      deal.tasks = [...deal.tasks.filter((e) => e.id !== data.id), data];
      commit(types.PIPELINE_UPDATE_DEAL, deal);
    } catch (error) {
      console.error(error);
    }
  },
  dragOver() {},
  dragLeave() {},
  dragEnter() {},
  dragStart({ commit, state }, event) {
    const el = event.item;
    const showDrop = [] as any[];

    if (el.classList.contains("trash")) {
      showDrop.push("restore");
    }
    // if (el.classList.contains("won")) showDrop.push("trash")
    // if (el.classList.contains("lost")) showDrop.push("trash")

    if (
      !el.classList.contains("trash") &&
      !el.classList.contains("won") &&
      !el.classList.contains("lost")
    ) {
      showDrop.push("won", "lost", "trash");
      if (state.pipelines.filter((e) => !e.is_archive).length > 1) {
        showDrop.push("move");
      }
    } else {
      showDrop.push("restore");
    }
    commit(types.PIPELINE_SET_DRAGGING, true);
    commit(types.PIPELINE_SET_SHOW_DROP, showDrop);
  },
  dragEnd({ commit, state }, event) {
    const stage_from = event.from.closest(".stage")?.dataset.id;
    const stage_to = event.to.closest(".stage")?.dataset.id;
    const el = event.item;
    const deal_id = el.dataset?.id;

    const shouldAbortPipelineUpdate =
      !deal_id ||
      !stage_to ||
      stage_from === stage_to ||
      el.classList.contains("trash") ||
      el.classList.contains("won") ||
      el.classList.contains("lost");

    if (shouldAbortPipelineUpdate) {
      commit(types.PIPELINE_SET_DRAGGING, false);
      if (deal_id && stage_from) {
        commit(types.PIPELINE_DEAL_SET_STAGE, {
          deal_id: deal_id,
          stage_id: stage_from,
        });
      }
      return false;
    }

    commit(types.PIPELINE_SET_DRAGGING, false);
    const index = state.deals.findIndex((e) => e.id == deal_id);
    const old_stage = state.deals[index].stage_id;
    commit(types.PIPELINE_DEAL_SET_STAGE, {
      deal_id: deal_id,
      stage_id: stage_to,
    });
    httpApiClient
      .patch(`deal-move/${deal_id}`, {
        stage: stage_to,
      })
      .catch(() => {
        commit(types.PIPELINE_DEAL_SET_STAGE, { deal_id, stage_id: old_stage });
      });
  },
  dropWon({ dispatch }, { deal_id, won_reason }) {
    const { index, old_deal } = getDealById({ deal_id, state });
    state.deals.splice(index, 1);

    httpApiClient
      .patch(`deal-won/${deal_id}`, {
        won_reason,
      })
      .then(() => {
        dispatch("fetchDeals");
        Events.$emit("deal-won", deal_id);
      })
      .catch(() => {
        state.deals.push(old_deal);
        Events.$emit("deal-won-error", old_deal);
      });
    // dispatch("dropAction", {
    //   action: "won",
    //   deal_id: deal_id,
    //   won_reason: won_reason,
    // })
  },
  dropTrash({ dispatch }, event) {
    const deal_id = event.item.getAttribute("data-id");
    const { index, old_deal } = getDealById({ deal_id, state });

    state.deals.splice(index, 1);
    httpApiClient
      .patch(`deal-trash/${deal_id}`)
      .then(() => {
        dispatch("fetchDeals");
        Events.$emit("deal-trash", deal_id);
      })
      .catch(() => {
        state.deals.push(old_deal);
        Events.$emit("deal-trash-error", old_deal);
      });
  },
  dropLost({ dispatch }, { deal_id, lost_reason }) {
    const { index, old_deal } = getDealById({ deal_id, state });
    state.deals.splice(index, 1);

    httpApiClient
      .patch(`deal-lost/${deal_id}`, {
        lost_reason,
      })
      .then(() => {
        dispatch("fetchDeals");
        Events.$emit("deal-lost", deal_id);
      })
      .catch(() => {
        state.deals.push(old_deal);
        Events.$emit("deal-lost-error", old_deal);
      });
    // dispatch("dropAction", {
    //   action: "lost",
    //   deal_id: deal_id,
    //   lost_reason: lost_reason,
    // })
  },
  dropMovePipeline({ dispatch }, { deal_id, pipeline_id }) {
    const { index, old_deal } = getDealById({ deal_id, state });
    state.deals.splice(index, 1);

    httpApiClient
      .patch(`deal-move-pipeline/${deal_id}`, {
        pipeline_id,
      })
      .then(() => {
        dispatch("fetchDeals");
        Events.$emit(`deal-move_pipeline`, deal_id);
      })
      .catch(() => {
        state.deals.push(old_deal);
        Events.$emit(`deal-move_pipeline-error`, old_deal);
      });
  },
  dropRestore({ dispatch }, event) {
    const deal_id = event.item.getAttribute("data-id");
    const { index, old_deal } = getDealById({ deal_id, state });
    state.deals.splice(index, 1);

    httpApiClient
      .patch(`deal-restore/${deal_id}`)
      .then(() => {
        dispatch("fetchDeals");
        Events.$emit("deal-restore", deal_id);
      })
      .catch(() => {
        state.deals.push(old_deal);
        Events.$emit("deal-restore-error", old_deal);
      });
    // dispatch("dropAction", { action: "restore", deal_id })
  },
  /* eslint-disable no-unused-vars */
  async dropAction(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    { state, dispatch },
    { action, deal_id }
  ) {
    const index = state.deals.findIndex((e) => e.id == deal_id);
    // const old_deal = JSON.parse(JSON.stringify(state.deals[index]))
    state.deals.splice(index, 1);
    Events.$emit(`deal-${action}`, deal_id);

    // httpApiClient
    //   .patch(`deal/${deal_id}`, {
    //     action: action,
    //     pipeline_id: pipeline_id,
    //     won_reason: won_reason,
    //     lost_reason: lost_reason,
    //   })
    //   .then(() => {
    //     dispatch("fetchDeals")
    //     Events.$emit(`deal-${action}`, deal_id)
    //   })
    //   .catch(() => {
    //     state.deals.push(old_deal)
    //     Events.$emit(`deal-${action}-error`, old_deal)
    //   })
  },
  dealRestore({ dispatch }, deal_id) {
    httpApiClient
      .patch(`deal-restore/${deal_id}`)
      .then(({ data }) => {
        dispatch("fetchDeals");
        Events.$emit(`deal-restore`, data);
      })
      .catch(() => {
        Events.$emit(`deal-restore-error`);
      });
  },
  setCurrentPipeline({ state }, pipeline_id) {
    httpApiClient
      .patch(`user/${state.user.id}`, {
        pipeline_id: pipeline_id,
      })
      .then(() => {
        state.user.pipeline_id = pipeline_id;
        Events.$emit(`init-pipeline`);
      })
      .catch(() => {
        Events.$emit(`deal-pipeline-error`, pipeline_id);
      });
  },
  unarchivePipeline({ dispatch }, pipeline_id) {
    httpApiClient
      .patch(`pipeline/${pipeline_id}`, {
        action: "archive",
      })
      .then(() => {
        dispatch("fetchPipelines");
      })
      .catch((e) => {
        console.error(e);
      });
  },
  archivePipeline({ dispatch }, pipeline_id) {
    httpApiClient
      .patch(`pipeline/${pipeline_id}`, {
        action: "archive",
      })
      .then(() => {
        dispatch("fetchPipelines");
      })
      .catch((e) => {
        console.error(e);
      });
  },
  addDeal({ commit }, deal) {
    commit(types.PIPELINE_ADD_DEAL, deal);
  },
  updateDeal({ commit }, deal) {
    commit(types.PIPELINE_UPDATE_DEAL, deal);
  },
  setMode({ commit }, mode) {
    commit(types.PIPELINE_SET_MODE, mode);
  },
};

const getters = {
  getMode: (state) => state.mode,
  isLoading: (state) => state.loading,
  isDragging: (state) => state.dragging,
  showDrop: (state) => state.show_drop,
  getSearch: (state) => state.search,
  getPipeline: (state) => state.pipeline,
  getPipelines:
    (state) =>
    (search = "") => {
      if (!search || search == "")
        return state.pipelines.filter((e) => !e.is_archive);
      else {
        const options = {
            shouldSort: false,
            threshold: 0.3,
            location: 0,
            distance: 100,
            keys: ["name"],
          },
          fuse = new Fuse(
            state.pipelines.filter((e) => !e.is_archive),
            options
          );
        return fuse.search(search.trim()).map((item) => item.item);
      }
    },
  getPipelinesArchive:
    (state) =>
    (search = "") => {
      if (!search || search == "")
        return state.pipelines.filter((e) => e.is_archive);
      else {
        const options = {
            shouldSort: false,
            threshold: 0.3,
            location: 0,
            distance: 100,
            keys: ["name"],
          },
          fuse = new Fuse(
            state.pipelines.filter((e) => e.is_archive),
            options
          );
        return fuse.search(search.trim()).map((item) => item.item);
      }
    },
  countPipelines: (state) =>
    state.pipelines.filter((e) => !e.is_archive).length,
  countPipelinesArchive: (state) =>
    state.pipelines.filter((e) => e.is_archive).length,
  getStage: (state) => (id) => state.stages.find((e) => e.id == id),
  getStages: (state) => state.stages.sort((a, b) => a.order_nr - b.order_nr),
  getDeal: (state) => (id) => state.deals.find((e) => e.id == id),
  getDealsCount: (_state, context) => (stage_id) =>
    context.getDeals(stage_id).filter((e) => e.stage_id == stage_id).length,
  dealTotal: () => (deal) => {
    return deal.value ?? 0;
  },
  getContacts: (state) => (deal_id) =>
    state.contacts.filter(
      (e) =>
        state.has_persons
          .filter((e) => e.model_id == deal_id)
          .map((e) => e.person_id)
          .indexOf(e.id) !== -1
    ),
  getPipelineUser: (state) =>
    state.pipelines.find((e) => e.id == state.user.pipeline_id),
  getUserFilter: (state) => (type) =>
    state.user ? state.user.filters.find((e) => e.type == type) : null,
  getTasks: (state) => (deal_id) =>
    state.deals
      .find((e) => e.id == deal_id)
      .tasks.sort((a, b) => {
        const dt_a = dayjs(`${a.due_at}`, "YYYY-MM-DD HH:mm"),
          dt_b = dayjs(`${b.due_at}`, "YYYY-MM-DD HH:mm");

        if (dt_a.isSame(dt_b)) return 0;
        if (dt_a.isBefore(dt_b)) return -1;
        else return 1;
      })
      .reverse(),
  getDeals: (state) => (stage_id) => {
    let data = state.deals
      .filter((e) => e.stage_id == stage_id)
      .map((item) => ({ ...item }));

    if (state.search) {
      const options = {
          shouldSort: false,
          threshold: 0.3,
          location: 0,
          distance: 100,
          keys: ["name", "company.name"],
        },
        fuse = new Fuse(data, options);
      data = fuse.search(state.search).map((item) => item.item);
    }

    const deals = data.map((deal) => {
      const tasks = state.tasks.filter((e) => e.deal_id == deal.id);
      let has_warning = false;
      let has_error = false;
      const is_done = tasks.every((e) => e.is_done);

      deal.priority = 1;
      if (!tasks.length) {
        deal.priority = 0;
      } else if (is_done) {
        deal.priority = -1;
      } else {
        for (const act of tasks) {
          const diff = dayjs(`${act.due_date}`, "YYYY-MM-DD HH:mm").diff(
            dayjs(),
            "minutes"
          );
          if (!act.is_done && diff > 0 && diff < 4 * 60) {
            has_warning = true;
          }
          if (!act.is_done && diff <= 0) {
            has_error = true;
          }
        }
        if (has_error) {
          deal.priority = 2;
        }
        if (has_warning) {
          deal.priority = 1;
        }
      }
      return deal;
    });
    return deals.sort((a, b) => a.priority < b.priority);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
