<template>
  <transition name="fadeloader">
    <div v-show="show" class="loader-container h-full">
      <div class="wrapper">
        <div class="progress-container flex flex-col items-center">
          <svg
            :id="canSuccess ? 'SyW9T2x6z' : 'svg_off'"
            :style="{ opacity: show ? 1 : 0 }"
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="600px"
            height="600px"
            version="1.1"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 149.06 149.06"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="rJebc6hlaM_rkpzSagTM"
              data-animator-group="true"
              data-animator-type="2"
            >
              <g id="rJebc6hlaM">
                <rect id="H1Wb562xpM" width="149.06" height="149.06" />
                <g id="_2788235298752">
                  <path
                    id="SkX-5TngTf"
                    d="M48.5 51.42l-0.29 -16c-0.13,-7.28 -0.04,-19.24 9.76,-27.7 13.12,-7.58 19.91,-7.63 33.11,0l16.56 9.55 -16.56 9.56c10.18,-4.78 16.56,-6.1 25.58,-4.35l-9.02 -5.21 -59.14 34.15z"
                  />
                  <path
                    id="ByEZ5anl6f"
                    d="M8.33 36.37l0.01 0 -0.01 0 0 0zm33.12 -0.12c0.95,-11.12 3.01,-17.29 9,-24.19l-9 5.2 0 -0.01 0 0 0 0 -16.56 9.56c-13.2,7.62 -16.56,13.53 -16.56,28.68l0 19.11 33.12 19.12 0 -57.47z"
                  />
                  <path
                    id="HJrW562eaG"
                    d="M82.49 108.1c-5.4,2.41 -12.44,1.55 -15.83,-0.01l-42.48 -24.53c-8.73,-6.14 -12.91,-10.96 -15.85,-19.4l0.03 10.36 0 19.03c0,15.24 3.44,21.1 16.55,28.67l16.56 9.56 41.02 -23.68z"
                  />
                  <path
                    id="HyLbqangaM"
                    d="M57.51 122.51l50.16 -28.96 0 38.24 -14.98 8.64 -1.58 0.92c-13.12,7.57 -19.91,7.62 -33.11,0l-16.56 -9.56 -9 -5.23c8.86,1.74 15.18,0.51 25.07,-4.05z"
                  />
                  <path
                    id="HywbcpnxTf"
                    d="M107.62 55.49c4.87,-19.12 23.71,-19.12 33.11,-19.12l0 38.24 -0.04 19.11c-0.03,15.15 -3.4,21.05 -16.62,28.65l-16.45 9.45 -9.03 5.2c6.01,-6.91 8.07,-13.08 9.03,-24.23l0 -57.3zm33.11 57.21l0 0.14 -0.12 0.07 0.12 -0.21z"
                  />
                </g>
              </g>
            </g>
          </svg>
          <div class="block text-center">
            <div
              :style="{
                width: `${percent}%`,
                height: height,
                opacity: show ? 1 : 0,
              }"
              class="loader-progress"
              :class="{
                '!bg-primary-400': canSuccess,
                '!bg-red-600': !canSuccess,
              }"
            ></div>
            <div
              :style="{ width: `100%`, height: height, opacity: show ? 1 : 0 }"
              class="loader-progress-bg"
            ></div>
            <p v-if="!canSuccess">
              A aplicação demorou demais para responder.
              <a href="">tentar novamente</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { nextTick } from "vue";
// https://github.com/nuxt/nuxt.js/blob/master/lib/app/components/nuxt-loading.vue
export default {
  props: {
    timeout: {
      default: 60000,
      type: Number,
    },
  },

  data: () => ({
    percent: 0,
    show: false,
    canSuccess: true,
    height: "6px",
    color: "#3e8ef7",
    failedColor: "#ff4c52",
    seconds: 0,
  }),

  methods: {
    start() {
      this.show = true;
      this.canSuccess = true;

      if (this._timer) {
        clearInterval(this._timer);
        this.percent = 0;
        this.seconds = 0;
      }
      this._timer = setInterval(() => {
        this.seconds += 100;
        if (this.seconds > this.timeout) {
          this.set(100);
          this.pause();
          this.fail();
        }
      }, 100);
      return this;
    },
    set(num) {
      this.show = true;
      this.canSuccess = true;
      this.percent = Math.floor(num);
      return this;
    },
    get() {
      return Math.floor(this.percent);
    },
    increase(num) {
      this.percent = this.percent + Math.floor(num);
      return this;
    },
    decrease(num) {
      this.percent = this.percent - Math.floor(num);
      return this;
    },
    finish() {
      this.percent = 100;
      this.seconds = 0;
      this.hide();
      return this;
    },
    pause() {
      clearInterval(this._timer);
      return this;
    },
    hide() {
      clearInterval(this._timer);
      this._timer = null;

      setTimeout(() => {
        this.show = false;
        nextTick(() => {
          setTimeout(() => {
            this.percent = 0;
            this.seconds = 0;
          }, 200);
        });
      }, 500);
      return this;
    },
    fail() {
      this.canSuccess = false;
      return this;
    },
  },
};
</script>

<style lang="scss" scoped>
.fadeloader-enter-active,
.fadeloader-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fadeloader-enter,
.fadeloader-leave-active {
  opacity: 0;
}

.loader-container {
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(#fff, 1);
  display: table;
  #svg_off {
    -webkit-filter: grayscale(100%);
    -webkit-filter: grayscale(1);
    filter: grayscale(100%);

    opacity: 0.6 !important;
  }
  svg {
    width: 150px;
    height: 150px;
  }
  $width: 260px;
  $height: 5px;
  .wrapper {
    width: $width;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    .progress-container {
      width: 100%;
      .block {
        width: $width;
        margin: 0 auto;
      }
      .loader-progress {
        height: $height;
        width: 0%;
        max-width: $width;
        transition: width 0.4s, opacity 0.4s;
        opacity: 1;
        background-color: #ccc;
        z-index: 9999;
        margin-top: 30px;
        border-radius: 10px;
      }
      .loader-progress-bg {
        background: rgb(235, 235, 235);
        width: 100%;
        height: $height;
        border-radius: 10px;
        position: relative;
        z-index: -1;
        top: $height * -1 - 1;
      }
    }
  }
}
</style>
