import Cookies from "js-cookie";
import { USER_ROLES } from "@/constants/user-roles";
import * as types from "../mutation-types";
import { httpApiClient, httpClient } from "@/services";

// state
const state = {
  user: null,
  token: Cookies.get("token"),
};

// getters
const getters = {
  user: (state) => state.user,
  isAdmin: (state) => state.user?.role_id === USER_ROLES.ADMIN,
  token: (state) => state.token,
  check: (state) => state.user !== null,
};

// mutations
const mutations = {
  [types.SAVE_TOKEN](state, { token, remember }) {
    state.token = token;
    Cookies.set("token", token, { expires: remember ? 30 : 1 });
  },

  [types.FETCH_USER_SUCCESS](state, { user }) {
    state.user = user;
  },

  [types.FETCH_USER_FAILURE](state) {
    state.token = null;
    Cookies.remove("token");
  },

  [types.LOGOUT](state) {
    state.user = null;
    state.token = null;

    Cookies.remove("token");
  },

  [types.UPDATE_USER](state, { user }) {
    state.user = user;
  },
};

// actions
const actions = {
  saveToken({ commit }, payload) {
    commit(types.SAVE_TOKEN, payload);
  },

  async fetchUser({ commit }) {
    try {
      const { data } = await httpApiClient.get("me");

      commit(types.FETCH_USER_SUCCESS, { user: data.data });
    } catch {
      commit(types.FETCH_USER_FAILURE);
    }
  },

  updateUser({ commit }, payload) {
    commit(types.UPDATE_USER, payload);
  },

  /**
   * Logs in the user.
   *
   * @param {Object} store - The Vuex store.
   * @param {Object} payload - The payload containing the login information.
   * @return {Promise} A promise that resolves when the login process is complete.
   */
  async login({ dispatch }, payload) {
    await httpClient.get("/sanctum/csrf-cookie", {
      withCredentials: true,
    });
    const { data } = await httpClient.post("/auth/login", payload, {
      withCredentials: true,
    });

    await dispatch(
      "auth/saveToken",
      {
        token: data.token,
        remember: payload.remember,
      },
      { root: true }
    );

    return await dispatch("auth/fetchUser", null, { root: true });
  },

  async logout({ commit }) {
    try {
      await httpApiClient.post("/logout");
      // TODO: ANALISAR O FUNCIONAMENTO DO CÓDIGO ABAIXO
      // (window as any).Echo.leave(
      //   `${(window as any).config.fqdn}.${
      //     (window as any).config.website_id
      //   }.online`
      // );
    } catch (e) {
      console.error(e);
    }

    commit(types.LOGOUT);
  },

  async fetchOauthUrl(_ctx, { provider }) {
    const { data } = await httpApiClient.post(`/oauth/${provider}`);

    return data.url;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
