import { definePreset } from "@primevue/themes";
import Aura from "@primevue/themes/aura";
import { dialog, drawer, toast, toggleswitch } from "./components";
import tinycolor from "tinycolor2";

const primaryColor = "#0c3455";

export const preset = definePreset(Aura, {
  semantic: {
    primary: {
      default: primaryColor,
      50: tinycolor(primaryColor).lighten(52).toHexString(),
      100: tinycolor(primaryColor).lighten(37).toHexString(),
      200: tinycolor(primaryColor).lighten(26).toHexString(),
      300: tinycolor(primaryColor).lighten(17).toHexString(),
      400: tinycolor(primaryColor).lighten(9).toHexString(),
      500: primaryColor,
      600: tinycolor(primaryColor).darken(6).toHexString(),
      700: tinycolor(primaryColor).darken(12).toHexString(),
      800: tinycolor(primaryColor).darken(18).toHexString(),
      900: tinycolor(primaryColor).darken(24).toHexString(),
      950: tinycolor(primaryColor).darken(30).toHexString(),
    },
  },
  components: {
    drawer,
    toast,
    dialog,
    toggleswitch,
  },
});
