/**************************************************************
 * Auth
 **************************************************************/
export const LOGOUT = "LOGOUT";
export const SAVE_TOKEN = "SAVE_TOKEN";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";

/**************************************************************
 * Settings
 **************************************************************/
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

/**************************************************************
 * Lang
 **************************************************************/
export const SET_LOCALE = "SET_LOCALE";

/**************************************************************
 * Fields
 **************************************************************/
export const SET_FIELDS = "SET_FIELDS";
export const SET_FIELD_TYPES = "SET_FIELD_TYPES";
export const SET_FIELD_GROUPS = "SET_FIELD_GROUPS";

/**************************************************************
 * Pipeline
 **************************************************************/
export const PIPELINE_SET_LOADING = "PIPELINE_SET_LOADING";
export const PIPELINE_SET_SEARCH = "PIPELINE_SET_SEARCH";
export const PIPELINE_SET_DRAGGING = "PIPELINE_SET_DRAGGING";
export const PIPELINE_SET_USER = "PIPELINE_SET_USER";
export const PIPELINE_SET_PIPELINE = "PIPELINE_SET_PIPELINE";
export const PIPELINE_SET_PIPELINES = "PIPELINE_SET_PIPELINES";
export const PIPELINE_SET_STAGES = "PIPELINE_SET_STAGES";
export const PIPELINE_SET_DEALS = "PIPELINE_SET_DEALS";
export const PIPELINE_SET_COMPANIES = "PIPELINE_SET_COMPANIES";
export const PIPELINE_SET_PRODUCTS = "PIPELINE_SET_PRODUCTS";
export const PIPELINE_SET_TASKS = "PIPELINE_SET_TASKS";
export const PIPELINE_SET_TASKS_TYPE = "PIPELINE_SET_TASKS_TYPE";
export const PIPELINE_SET_CURRENCIES = "PIPELINE_SET_CURRENCIES";
export const PIPELINE_SET_USERS = "PIPELINE_SET_USERS";
export const PIPELINE_SET_FIELDS = "PIPELINE_SET_FIELDS";
export const PIPELINE_SET_DEAL_PRODUCTS = "PIPELINE_SET_DEAL_PRODUCTS";
export const PIPELINE_SET_PERSONS = "PIPELINE_SET_PERSONS";
export const PIPELINE_SET_HAS_PERSONS = "PIPELINE_SET_HAS_PERSONS";
export const PIPELINE_SET_TASK = "PIPELINE_SET_TASK";
export const PIPELINE_SET_SHOW_DROP = "PIPELINE_SET_SHOW_DROP";
export const PIPELINE_ADD_DEAL = "PIPELINE_ADD_DEAL";
export const PIPELINE_UPDATE_DEAL = "PIPELINE_UPDATE_DEAL";
export const PIPELINE_DEAL_SET_STAGE = "PIPELINE_DEAL_SET_STAGE";
export const PIPELINE_SET_MODE = "PIPELINE_SET_MODE";

/**************************************************************
 * Tasks
 **************************************************************/
export const TASK_SET_LOADING = "TASK_SET_LOADING";
export const TASK_SET_SEARCH = "TASK_SET_SEARCH";
export const TASK_SET_DRAGGING = "TASK_SET_DRAGGING";
export const TASK_SET_USER = "TASK_SET_USER";
export const TASK_SET_TASK = "TASK_SET_TASK";
export const TASK_SET_TASKS = "TASK_SET_TASKS";
export const TASK_SET_MODE = "TASK_SET_MODE";
export const TASK_SET_DATE_START = "TASK_SET_DATE_START";
export const TASK_SET_DATE_END = "TASK_SET_DATE_END";
export const TASK_PREPARE_RANGE = "TASK_PREPARE_RANGE";
