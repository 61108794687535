import { store } from "@/store";

export default () => {
  const checked = store.getters["auth/check"];
  const token = store.getters["auth/token"];
  if (!checked && token) {
    try {
      return {
        promise: () => store.dispatch("auth/fetchUser"),
      };
    } catch (e) {
      console.error("Error on check-auth middleware", e);
    }
  }
};
