import { APP } from "@/core";
import axios, { AxiosError } from "axios";
import Cookie from "js-cookie";
import { router } from "@/router";

export const httpClient = axios.create();
export const httpApiClient = axios.create();

const requestInterceptor = (baseUrl: string) => {
  return (config) => {
    const xsrfToken = Cookie.get("XSRF-TOKEN");
    const token = Cookie.get("token");
    config.baseURL = baseUrl;
    config.headers["X-XSRF-TOKEN"] = xsrfToken;
    config.headers["Authorization"] = token ? `Bearer ${token}` : "";
    return config;
  };
};

const responseInterceptor = (response) => response;
const errorHandling = (error) => {
  if (error instanceof AxiosError) {
    if (error.response?.status === 401) {
      router.push({ name: "login" });
    }
  }
  return Promise.reject(error);
};

httpClient.interceptors.request.use(requestInterceptor(APP.API_BASE_URL));
httpClient.interceptors.response.use(responseInterceptor, errorHandling);
httpApiClient.interceptors.request.use(
  requestInterceptor(`${APP.API_BASE_URL}/api`)
);
httpApiClient.interceptors.response.use(responseInterceptor, errorHandling);
