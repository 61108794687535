import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.locale("pt-br");
dayjs.extend(isBetween);
dayjs.extend(CustomParseFormat);
dayjs.extend(relativeTime);
