import {
  faHandshake,
  faHome,
  faAddressCard,
  faBuilding,
  faTasks,
  faArchive,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faChevronDown,
  faPowerOff,
  faUserCircle,
  faCogs,
  faBell,
  faCircle as fasCircle,
  faGear,
  faSearch,
  faSyncAlt,
  faTable,
  faPlus,
  faTimes,
  faTrashAlt,
  faEnvelope,
  faExternalLink,
  faPencilAlt,
  faUpload,
  faCheck,
  faUndoAlt,
  faCalendarTimes,
  faCalendarWeek,
  faHourglassHalf,
  faHourglassStart,
  faBars,
  faClone,
  faSortUp,
  faUserFriends,
  faSortDown,
  faExclamationCircle,
  faUser,
  faCalendarAlt,
  faUserTie,
  faCamera,
  faUsers,
  faFileSignature,
  faFileUpload,
  faHistory,
  faInfoCircle,
  faMagic,
  faArrowLeft,
  faFont,
  faAlignJustify,
  faCalculator,
  faDollarSign,
  faCheckDouble,
  faPhone,
  faClock,
  faSortNumericUp,
  faCheckSquare,
  faBoxes,
  faUserShield,
  faCommentAlt,
  faMapMarkerAlt,
  faSortAmountDown,
  faAngleLeft,
  faAngleRight,
  faComments,
  faTrash,
  faQuestion,
  faMapMarkedAlt,
  faAngleDown,
  faCalendarCheck,
  faHandPointDown,
  faUndo,
  faGripVertical,
  faEllipsisH,
  faExclamationTriangle,
  faSort,
  faUserPlus,
  faHandHoldingUsd,
  faExternalLinkAlt,
  faBox,
  faIdCard,
  faObjectGroup,
  faFileExcel,
  faLink,
  faPhoneVolume,
  faChevronUp,
  faSlidersH,
  faFilter,
  faLock,
  faCircle,
  faArrowsAlt,
  faCrop,
  faSearchPlus,
  faSearchMinus,
  faArrowsAltH,
  faArrowsAltV,
  faStar,
  faCopy,
  faUserCheck,
  faQuestionCircle,
  faArrowRight,
  faFileImport,
  faThLarge,
  faFlagCheckered,
  faEye,
  faBoxOpen,
  faTimesCircle,
  faUtensils,
  faColumns,
  faChevronRight,
  faChevronLeft,
  faShare,
  faCalendar,
  faCheckCircle,
  faPaperclip,
  faStickyNote,
  faPlusCircle,
  faSatellite,
  faList,
  faEyeSlash,
  faSpinner,
  faExpand,
  faCog,
  faStepForward,
  faStepBackward,
  faFolder,
  faCut,
  faPaste,
  faThList,
  faTh,
  faLevelUpAlt,
  faCropAlt,
  faArrowUp,
  faArrowDown,
  faRedo,
  faSave,
  faDownload,
  faPlay,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faFileVideo,
  faFileAudio,
  faFile,
  faFileCode,
  faFileCsv,
  faFileWord,
  faFilePowerpoint,
  faFileImage,
  faStarHalfAlt,
  faSortNumericDown,
  faChartLine,
  faKey,
  faTag,
  faTags,
  faLocationArrow,
  faMoneyCheck,
  faLightbulb,
  faLayerGroup,
  faSuperscript,
  faVenusMars,
  faMapPin,
  faGlobeAmericas,
  faCity,
  faHandshakeSlash,
  faUserTag,
  faFingerprint,
  faSearchLocation,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCalendarCheck as farCalendarCheck,
  faCircle as farCircle,
  faTrashAlt as farTrashAlt,
  faStar as farStar,
  faFile as farFile,
  faFolder as farFolder,
  faClipboard as farClipboard,
  faHdd as farHdd,
  faFileAlt as farFileAlt,
  faFileImage as farFileImage,
  faFileCode as farFileCode,
  faFileAudio as farFileAudio,
  faFileVideo as farFileVideo,
  faFileArchive as farFileArchive,
  faFilePdf as farFilePdf,
  faFileWord as farFileWord,
  faFileExcel as farFileExcel,
  faFilePowerpoint as farFilePowerpoint,
  faSave as farSave,
  faCopy as farCopy,
  faEdit as farEdit,
  faListAlt as farListAlt,
  faFolderOpen as farFolderOpen,
  faHourglass as farHourglass,
} from "@fortawesome/free-regular-svg-icons";

import {
  faWhatsapp as fabWhatsapp,
  faWhatsappSquare as fabWhatsappSquare,
  faHtml5 as fabHtml5,
} from "@fortawesome/free-brands-svg-icons";

export const globalIcons = [
  faHandshake,
  faHome,
  faAddressCard,
  faBuilding,
  faTasks,
  faArchive,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faChevronDown,
  faPowerOff,
  faUserCircle,
  faCogs,
  faBell,
  fasCircle,
  farCircle,
  faGear,
  faSearch,
  faSyncAlt,
  faTable,
  faPlus,
  faTimes,
  faTrashAlt,
  faEnvelope,
  faExternalLink,
  faPencilAlt,
  faUpload,
  faCheck,
  faUndoAlt,
  faCalendarTimes,
  faCalendarWeek,
  faHourglassHalf,
  faHourglassStart,
  faBars,
  faClone,
  faSortUp,
  faUserFriends,
  faSortDown,
  faExclamationCircle,
  faUser,
  faCalendarAlt,
  faUserTie,
  faCamera,
  faUsers,
  faFileSignature,
  faFileUpload,
  faHistory,
  faInfoCircle,
  faMagic,
  faArrowLeft,
  faFont,
  faAlignJustify,
  faCalculator,
  faDollarSign,
  faCheckDouble,
  faPhone,
  faClock,
  faSortNumericUp,
  faCheckSquare,
  faBoxes,
  faUserShield,
  faCommentAlt,
  faMapMarkerAlt,
  faSortAmountDown,
  faAngleLeft,
  faAngleRight,
  faComments,
  faTrash,
  faQuestion,
  faMapMarkedAlt,
  faAngleDown,
  faCalendarCheck,
  farCalendarCheck,
  faHandPointDown,
  faUndo,
  faGripVertical,
  faEllipsisH,
  faExclamationTriangle,
  faSort,
  faUserPlus,
  faHandHoldingUsd,
  faExternalLinkAlt,
  faBox,
  faIdCard,
  faObjectGroup,
  faFileExcel,
  faLink,
  faPhoneVolume,
  fabWhatsapp,
  fabWhatsappSquare,
  faChevronUp,
  faSlidersH,
  faFilter,
  faLock,
  faCircle,
  faArrowsAlt,
  faCrop,
  faSearchPlus,
  faSearchMinus,
  faArrowsAltH,
  faArrowsAltV,
  faStar,
  faCopy,
  faUserCheck,
  faQuestionCircle,
  faArrowRight,
  faFileImport,
  faThLarge,
  faFlagCheckered,
  faEye,
  faBoxOpen,
  faTimesCircle,
  faUtensils,
  faColumns,
  faChevronRight,
  faChevronLeft,
  farTrashAlt,
  farStar,
  faShare,
  faCalendar,
  faCheckCircle,
  faPaperclip,
  faStickyNote,
  faPlusCircle,
  faSatellite,
  faList,
  faEyeSlash,
  faSpinner,
  faExpand,
  faCog,
  faStepForward,
  faStepBackward,
  faFolder,
  faCut,
  faPaste,
  faThList,
  faTh,
  farClipboard,
  farHdd,
  farFolder,
  farFile,
  faLevelUpAlt,
  farFileAlt,
  farFileImage,
  farFileCode,
  farFileAudio,
  farFileVideo,
  farFileArchive,
  farFilePdf,
  farFileWord,
  farFilePowerpoint,
  farFileExcel,
  faCropAlt,
  faArrowUp,
  faArrowDown,
  faRedo,
  faSave,
  farSave,
  faDownload,
  farCopy,
  farEdit,
  farListAlt,
  faPlay,
  farFolderOpen,
  faChartLine,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faFileVideo,
  faFileAudio,
  faFile,
  faFileCode,
  faFileCsv,
  faFileWord,
  faFilePowerpoint,
  faFileImage,
  faStarHalfAlt,
  faSortNumericDown,
  fabHtml5,
  farHourglass,
  faKey,
  faTag,
  faTags,
  faLocationArrow,
  faMoneyCheck,
  faLightbulb,
  faLayerGroup,
  faSuperscript,
  faVenusMars,
  faMapPin,
  faGlobeAmericas,
  faCity,
  faHandshakeSlash,
  faUserTag,
  faFingerprint,
  faSearchLocation,
];
