import { createI18n } from "vue-i18n";
import ptBR from "@/lang/pt-BR.json";
import en from "@/lang/en.json";
import { store } from "@/store";

export const messages = {
  en: en,
  "pt-BR": ptBR,
};

export const i18n = createI18n({
  locale: store.getters["lang/locale"],
  messages,
  fallbackLocale: "pt-BR",
  silentTranslationWarn: true,
});
