import "./index.css";
import "./style.scss";
import { createApp } from "vue";
import App from "./App.vue";
import { router } from "@/router";
import { globalIcons, i18n } from "@/plugins";
import PrimeVue from "primevue/config";
import Button from "primevue/button";
import { preset } from "./theme";
import { store } from "@/store";
import { httpApiClient } from "./services";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { modalInfo } from "@/plugins/modal-info";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import { toastNotify } from "./plugins/toast";
import { createHead, VueHeadMixin } from "@unhead/vue";
import { observeVisibility } from "./directives";
import Ripple from "primevue/ripple";
import pt from "@/lang/pt-BR.json";

const app = createApp(App).use(router);

library.add(...globalIcons);

app.config.performance = true;
app.config.globalProperties.$http = httpApiClient;
app.config.globalProperties.$openModalInfo = modalInfo;
app.config.globalProperties.$toastNotify = toastNotify;

app.use(i18n);
app.use(createHead());
app.use(store);
app.use(PrimeVue, {
  theme: {
    preset,
    options: {
      prefix: "",
      darkModeSelector: "",
      cssLayer: false,
    },
  },
  locale: pt.primevue,
});
app.use(ConfirmationService);
app.use(ToastService);
app.mixin(VueHeadMixin);
app.directive("observe-visibility", observeVisibility);

// eslint-disable-next-line vue/no-reserved-component-names
app.component("Button", Button);
app.component("fa", FontAwesomeIcon);

app.directive("tooltip", Tooltip);
app.directive("ripple", Ripple);

app.mount("#app");
