import Cookies from "js-cookie";
import * as types from "../mutation-types";

const state = {
  locale: window?.config?.locale || "pt-BR",
  locales: window?.config?.locales || [{ "pt-BR": "Português" }],
};

const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
};

const mutations = {
  [types.SET_LOCALE](state, { locale }) {
    state.locale = locale;
  },
};

const actions = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale });
    Cookies.set("locale", locale, { expires: 365 });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
