import { store } from "@/store";
import { RouteLocationNormalized } from "vue-router";

export default (to: RouteLocationNormalized) => {
  try {
    const check = store.getters["auth/check"];
    const settings = store.getters["settings/settings"];

    if (check && !settings) {
      return {
        promise: () => store.dispatch("settings/fetchSettings"),
      };
    }

    if (to.name === "product" && settings?.product_enabled !== 1) {
      // return next({ name: "403" })
      //TODO: refactor this middleware
    }
  } catch (e) {
    console.log(e);
  }
};
