import { routes } from "./routes";
import { createWebHistory, createRouter } from "vue-router";
import checkAuth from "@/middleware/check-auth";
import checkSettings from "@/middleware/check-settings";
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
} from "vue-router";

export const router = createRouter({
  history: createWebHistory(),
  routes,
});
import Event from "@/plugins/bus";

const middlewares = [checkAuth, checkSettings];

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalizedLoaded,
    next: NavigationGuardNext
  ) => {
    if (to.name === from.name) {
      return next();
    }
    let isLoading = false;
    try {
      for (const middleware of middlewares) {
        const result = middleware(to);
        if (result?.promise) {
          isLoading = true;
          Event.$emit("start-loading");
          await result.promise();
        }
      }
    } finally {
      if (isLoading) {
        Event.$emit("finish-loading");
      }
    }
    return next();
  }
);
