<template>
  <loading ref="loading" />
  <CustomConfirmDialog />
  <CustomToast />
  <router-view></router-view>
</template>

<script>
import Loading from "@/components/Loading.vue";
import CustomConfirmDialog from "@/components/CustomConfirmDialog.vue";
import CustomToast from "@/components/CustomToast.vue";
import Event from "@/plugins/bus";

export default {
  components: {
    Loading,
    CustomConfirmDialog,
    CustomToast,
  },

  head() {
    return {
      templateParams: {
        siteName: "UnionCRM",
      },
      titleTemplate: "%s · UnionCRM",
    };
  },

  beforeCreate() {
    Event.$on("start-loading", () => {
      this.$root.$refs.loading.start(0);
    });
    Event.$on("finish-loading", () => {
      this.$root.$refs.loading.finish();
    });
  },

  beforeUnmount() {
    Event.$off("start-loading", "finish-loading");
  },

  methods: {
    openModalInfo(event) {
      console.log("openModalInfo", event);
    },
  },
};
</script>

<style scoped></style>
