import Event from "@/plugins/bus";
import { ToastMessageOptions } from "primevue/toast";

class ToastNotify {
  /***
   * @param { string } content
   * @param { string } title
   */
  success(content, title, options = { life: 5000, buttons: [] }) {
    this.#showToast("success", content, title, options);
  }

  /***
   * @param { string } content
   * @param { string } title
   */
  error(content, title, options = { life: 5000, buttons: [] }) {
    this.#showToast("error", content, title, options);
  }

  /***
   * @param { string } content
   * @param { string } title
   */
  info(content, title, options = { life: 5000, buttons: [] }) {
    this.#showToast("info", content, title, options);
  }

  /***
   * @param { string } content
   * @param { string } title
   */
  warn(content, title, options = { life: 5000, buttons: [] }) {
    this.#showToast("warn", content, title, options);
  }

  /***
   * @param { 'success' | 'error' | 'info' | 'warn' } severity
   * @param { string } content
   * @param { string } title
   */
  #showToast(severity, content, title, options = { life: 5000, buttons: [] }) {
    Event.$emit("open-toast", {
      severity: severity,
      summary: title,
      detail: content,
      life: options.life ?? 5000,
      group: options?.buttons?.length ? "custom" : undefined,
      buttons: options?.buttons || [],
    } as ToastMessageOptions);
  }
}

export const toastNotify = new ToastNotify();
