<template>
  <ConfirmDialog
    ref="confirmDialogRef"
    :close-button="true"
    class="!border-none"
  >
    <template
      #container="{ message, acceptCallback, rejectCallback, closeCallback }"
    >
      <div class="p-dialog-header">
        <ModalHeader :header="message.header" @hide="print(closeCallback)" />
      </div>
      <div class="flex w-full max-w-2xl flex-col">
        <div class="p-4 flex gap-2 items-center">
          <i class="p-confirm-dialog-icon !text-3xl" :class="message.icon"></i>
          <!-- <pre>
            {{ Object.keys(event) }}
          </pre> -->
          <span>
            {{ message.message }}
          </span>
        </div>
        <div class="flex w-full justify-between px-4 py-2 bg-gray-100">
          <Button
            :severity="message.rejectProps?.severity"
            text
            @click="rejectCallback"
            >{{ message.rejectProps?.label ?? "Não" }}</Button
          >
          <Button
            :severity="message.acceptProps?.severity"
            @click="acceptCallback"
            >{{ message.acceptProps?.label ?? "Sim" }}
          </Button>
        </div>
      </div>
    </template>
  </ConfirmDialog>
</template>

<script>
import ConfirmDialog from "primevue/confirmdialog";
import ModalHeader from "@/components/modal-header/index.vue";

export default {
  components: {
    ConfirmDialog,
    ModalHeader,
  },

  methods: {
    print(callback) {
      this.$refs.confirmDialogRef.visible = false;
      if (callback) {
        callback();
      }
    },
  },
};
</script>
