import { createStore } from "vuex";

import authModule from "./modules/auth";
import langModule from "./modules/lang";
import settingsModule from "./modules/settings";
import fieldsModule from "./modules/fields";
import pipelineModule from "./modules/pipeline";

export const store = createStore({
  modules: {
    auth: authModule,
    lang: langModule,
    settings: settingsModule,
    fields: fieldsModule,
    pipeline: pipelineModule,
  },
});
