<template>
  <div
    class="flex w-full text-white bg-primary-500 justify-between items-center py-4 px-4"
    :class="{ 'rounded-t-xl': rounded }"
  >
    <div class="text-xl font-medium">
      {{ header }}
      <slot></slot>
    </div>
    <PvButton
      v-if="showCloseIcon"
      icon="pi pi-times"
      plain
      rounded
      @click="hide"
    />
  </div>
</template>

<script>
import PvButton from "primevue/button";

export default {
  name: "ModalHeader",

  components: {
    PvButton,
  },

  props: {
    showCloseIcon: {
      type: Boolean,
      default: true,
    },
    header: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      required: false,
    },
  },

  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>
