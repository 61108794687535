// import auth from "@middleware/auth"
import { RouteRecordRaw } from "vue-router";
import auth from "@/middleware/auth";

/**
 * Auth
 */
const Login = () => import("@/pages/auth/login.vue");
const PasswordEmail = () => import("@/pages/auth/password/email.vue");

/**
 * Layout
 */
const LayoutMain = () => import("@/layouts/main.vue");
const LayoutModules = () => import("@/layouts/modules.vue");
/**
 * Core
 */
const Dashboard = () => import("@/pages/dashboard/index.vue");
const Pipeline = () => import("@/pages/core/pipeline.vue");
const Contact = () => import("@/pages/core/contact.vue");
const Company = () => import("@/pages/core/company.vue");
const Product = () => import("@/pages/core/product.vue");
const Task = () => import("@/pages/core/task.vue");

/**
 * Settings
 */
const Settings = () => import("@/pages/settings/index.vue");
const SettingsGeneral = () => import("@/pages/settings/general.vue");
const SettingsHome = () => import("@/pages/settings/home.vue");

/**
 * Account
 */
const SettingsAccountProfile = () =>
  import(
    "@/pages/settings/account/profile.vue" /* webpackChunkName: "settings" */
  );
const SettingsAccountPassword = () =>
  import(
    "@/pages/settings/account/password.vue" /* webpackChunkName: "settings" */
  );
const SettingsAccountPbx = () =>
  import("@/pages/settings/account/pbx.vue" /* webpackChunkName: "settings" */);
const SettingsAccountSz = () =>
  import("@/pages/settings/account/sz.vue" /* webpackChunkName: "settings" */);
const SettingsAccountMailfrom = () =>
  import(
    "@/pages/settings/account/mailfrom.vue" /* webpackChunkName: "settings" */
  );
const SettingsAccountMailtest = () =>
  import(
    "@/pages/settings/account/mailtest.vue" /* webpackChunkName: "settings" */
  );

/**
 * User
 */
const SettingsUser = () =>
  import("@/pages/settings/user/index.vue" /* webpackChunkName: "settings" */);

/**
 * Field
 */
const SettingsField = () =>
  import("@/pages/settings/field/index.vue" /* webpackChunkName: "settings" */);
const SettingsFieldPage = () =>
  import("@/pages/settings/field/page.vue" /* webpackChunkName: "settings" */);

/**
 * Integrations
 */
const SettingsIntegration = () =>
  import(
    "@/pages/settings/integration/index.vue" /* webpackChunkName: "settings" */
  );
const SettingsIntegrationPbx = () =>
  import(
    "@/pages/settings/integration/pbx.vue" /* webpackChunkName: "settings" */
  );
const SettingsIntegrationSz = () =>
  import(
    "@/pages/settings/integration/sz.vue" /* webpackChunkName: "settings" */
  );

/**
 * Data Import
 */
const SettingsDataImport = () =>
  import(
    "@/pages/settings/data/import/index.vue" /* webpackChunkName: "settings" */
  );
const SettingsDataImportDeal = () =>
  import(
    "@/pages/settings/data/import/deal.vue" /* webpackChunkName: "settings" */
  );
const SettingsDataImportProduct = () =>
  import(
    "@/pages/settings/data/import/product.vue" /* webpackChunkName: "settings" */
  );
const SettingsDataImportContact = () =>
  import(
    "@/pages/settings/data/import/contact.vue" /* webpackChunkName: "settings" */
  );
const SettingsDataImportCompany = () =>
  import(
    "@/pages/settings/data/import/company.vue" /* webpackChunkName: "settings" */
  );
const SettingsDataImportHistory = () =>
  import(
    "@/pages/settings/data/import/history.vue" /* webpackChunkName: "settings" */
  );

const SettingsDataImportHistoryLogs = () =>
  import(
    "@/pages/settings/data/import/logs.vue" /* webpackChunkName: "settings" */
  );

/**
 * Data Export
 */
const SettingsDataExportHistory = () =>
  import(
    "@/pages/settings/data/export/history.vue" /* webpackChunkName: "settings" */
  );

/**
 * Developer
 */
const SettingsDeveloperTokens = () =>
  import(
    "@/pages/settings/developer/tokens.vue" /* webpackChunkName: "settings" */
  );

const SettingsDeveloperWebhook = () =>
  import(
    "@/pages/settings/developer/webhook/list/index.vue" /* webpackChunkName: "settings" */
  );
const SettingsDeveloperWebhookGroups = () =>
  import(
    "@/pages/settings/developer/webhook/groups/index.vue" /* webpackChunkName: "settings" */
  );
const SettingsDeveloperWebhookLogs = () =>
  import(
    "@/pages/settings/developer/webhook/logs/index.vue" /* webpackChunkName: "settings" */
  );
const SettingsDeveloperMailserver = () =>
  import(
    "@/pages/settings/developer/mailserver.vue" /* webpackChunkName: "settings" */
  );
/**
 * Error
 */
const Error404 = () => import("@/pages/errors/404.vue");
const Error500 = () => import("@/pages/errors/500.vue");
const Error403 = () => import("@/pages/errors/403.vue");

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "layoutMain",
    component: LayoutMain,
    beforeEnter: auth,
    children: [
      {
        path: "",
        name: "layoutModules",
        component: LayoutModules,
        children: [
          {
            path: "",
            name: "home",
            component: Dashboard,
          },
          {
            path: "/pipeline",
            name: "pipeline",
            component: Pipeline,
          },
          {
            path: "/contact",
            name: "contact",
            component: Contact,
          },
          {
            path: "/company",
            name: "company",
            component: Company,
            props: {
              hideLoader: false,
            },
          },
          {
            path: "/product",
            name: "product",
            component: Product,
            props: {
              hideLoader: false,
            },
          },
          {
            path: "/task",
            name: "task",
            component: Task,
            props: {
              hideLoader: false,
            },
          },
        ],
      },

      /**
       * Settings
       */
      {
        path: "/settings",
        component: Settings,
        children: [
          {
            path: "",
            name: "settings.home",
            component: SettingsHome,
          },
          {
            path: "general",
            name: "settings.general",
            component: SettingsGeneral,
          },
          {
            path: "account",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.account",
                redirect: {
                  name: "settings.account.profile",
                },
              },
              {
                path: "profile",
                name: "settings.account.profile",
                component: SettingsAccountProfile,
              },
              {
                path: "password",
                name: "settings.account.password",
                component: SettingsAccountPassword,
              },
              {
                path: "pbx",
                name: "settings.account.pbx",
                component: SettingsAccountPbx,
              },
              {
                path: "sz",
                name: "settings.account.sz",
                component: SettingsAccountSz,
              },
              {
                path: "mailfrom",
                name: "settings.account.mailfrom",
                component: SettingsAccountMailfrom,
              },
              {
                path: "mailtest",
                name: "settings.account.mailtest",
                component: SettingsAccountMailtest,
              },
            ],
          },
          {
            path: "user",
            name: "settings.user",
            component: SettingsUser,
          },
          {
            path: "field",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.field",
                component: SettingsField,
              },
              {
                path: ":model",
                name: "settings.field.page",
                component: SettingsFieldPage,
              },
            ],
          },
          {
            path: "integration",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.integration",
                component: SettingsIntegration,
              },
              {
                path: "pbx",
                name: "settings.integration.pbx",
                component: SettingsIntegrationPbx,
              },
              {
                path: "sz",
                name: "settings.integration.sz",
                component: SettingsIntegrationSz,
              },
            ],
          },

          {
            path: "import",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.data-import",
                component: SettingsDataImport,
              },
              {
                path: "deal",
                name: "settings.data-import.deal",
                component: SettingsDataImportDeal,
              },
              {
                path: "product",
                name: "settings.data-import.product",
                component: SettingsDataImportProduct,
              },
              {
                path: "company",
                name: "settings.data-import.company",
                component: SettingsDataImportCompany,
              },
              {
                path: "contact",
                name: "settings.data-import.contact",
                component: SettingsDataImportContact,
              },
            ],
          },
          {
            path: "import-history",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.data-import-history",
                component: SettingsDataImportHistory,
              },
              {
                path: "logs/:id",
                name: "settings.data-import-history.logs",
                component: SettingsDataImportHistoryLogs,
              },
              {
                path: "id/:id",
                name: "settings.data-import-history-view",
                component: SettingsDataImportHistory,
                props: true,
              },
            ],
          },
          {
            path: "export-history",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.data-export-history",
                component: SettingsDataExportHistory,
              },
              {
                path: "id/:id",
                name: "settings.data-export-history-view",
                component: SettingsDataExportHistory,
                props: true,
              },
            ],
          },
          {
            path: "developer/webhook",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.developer.webhook",
                redirect: {
                  name: "settings.developer.webhook.list",
                },
              },
              {
                path: "list",
                name: "settings.developer.webhook.list",
                component: SettingsDeveloperWebhook,
              },
              {
                path: "group",
                name: "settings.developer.webhook.group",
                component: SettingsDeveloperWebhookGroups,
              },
              {
                path: "logs/:id",
                name: "settings.developer.webhook.logs",
                component: SettingsDeveloperWebhookLogs,
              },
            ],
          },
          {
            path: "developer/tokens",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.developer.tokens",
                component: SettingsDeveloperTokens,
              },
            ],
          },
          {
            path: "developer/mailserver",
            beforeEnter: auth,
            children: [
              {
                path: "",
                name: "settings.developer.mailserver",
                component: SettingsDeveloperMailserver,
              },
            ],
          },
        ],
      },
    ],
  },

  /**
   * auth
   */
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      hideNavBar: true,
    },
  },
  {
    path: "/password/request",
    name: "password.request",
    component: PasswordEmail,
    meta: {
      hideNavBar: true,
    },
  },
  /**
   * Error
   */
  {
    path: "/403",
    name: "403",
    meta: {
      hideNavBar: true,
    },
    component: Error403,
  },
  {
    path: "/500",
    name: "500",
    meta: {
      hideNavBar: true,
    },
    component: Error500,
  },
  {
    path: "/:pathMatch(.*)*",
    meta: {
      hideNavBar: true,
    },
    component: Error404,
  },
];
