import { Directive } from "vue";

export const observeVisibility: Directive<any, any> = {
  mounted(el, binding) {
    const options = {
      root: binding.value.root || null,
      rootMargin: binding.value.rootMargin || "0px",
      threshold: binding.value.threshold || 0.1,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (typeof binding.value === "function") {
            binding.value(entry);
          } else {
            console.error("Callback is not a function");
          }
        }
      });
    };
    el._observer = new IntersectionObserver(callback, options);
    el._observer.observe(el);
  },
  unmounted(el) {
    if (el._observer) {
      el._observer.disconnect();
    }
  },
};
