export const drawer = {
  root: {
    background: "{overlay.modal.background}",
    borderColor: "{overlay.modal.border.color}",
    color: "{overlay.modal.color}",
    borderRadius: "{overlay.modal.border.radius}",
    shadow: "{overlay.modal.shadow}",
  },
  header: {
    padding: "0",
  },
  content: {
    padding: "0",
  },
};
